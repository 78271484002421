.auth-comp {
    display: flex;
    align-items: flex-start;
}

.auth-header { 
    width: 50%;
    margin-top: 5%;
}

.auth-logo {
    width: 60%;
    margin-bottom: 30px;
}

.auth-register {
    text-decoration: underline;
    cursor: pointer;
    padding-left: 5%;
    margin-top: 20px;
}