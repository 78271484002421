.main-body {
    display: flex;
}

.main-content {
    width: 100%;
    padding: 1.5rem
}

.main-heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.main-heading h2 {
    margin: 0
}

.main-heading p {
    margin-top: 8px;
    font-size: 0.9rem;
    color: #808080;

}

.main-heading :hover p {
    margin-top: 8px;
    font-size: 0.9rem;
    color: #000000;
    text-decoration: underline;
}

.numbers {
    margin-top: 2rem;
}

.number-date p {
    margin: 0;
    font-size: 0.8rem;
}

.number-date {
    background-color: #F2F5F8;
    padding: 8px;
    width: 8%;
    text-align: center;
    border-radius: 4px;
}

.number-stats {
 display: flex;
 justify-content: space-between;   
 margin-top: 20px;
margin-bottom: 20px;
gap: 30px;
}

.number-stats_single{
    border: 1px solid #F4F4F4;
    width: 100%;
    padding: 20px;
}

.number-stats_single img {
    width: 2rem;
}

.number-stats_single p {
    margin: 0;
    margin-top: 8px;
    font-size: 0.8rem;
    color: #808080;
}

.number-stats_single h5 {
    margin: 0;
    margin-top: 8px;
    font-size: 1.1rem;
}

.number-stats_single:hover {
    background-color: #F4F4F4;
    border: 1px solid #F4F4F4;
    width: 100%;
    padding: 20px;
}

.numbers-graph {
    width: 100%;
    margin-top: 40px;
}

.second_column { 
    display: flex;
    gap: 30px;
}

.campaign_column {
    border: 1px solid #F4F4F4;
    width: 60%;
    padding: 20px;
}

.campaign_heading h5 {
    font-size: 1.1rem;
}

.campaign_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.campaign_sent {
    padding: 8px;
    background-color: #E1FFE5;
    color: #07B71B;
    border-radius: 4px;
}

.campaign_analytics {
    cursor: pointer;
    text-decoration: underline;
    color: #808080;
    display: flex;
    justify-content: center;
}

.campaign_analytics p {
    text-decoration: underline;
    color: #808080;
}

.campaign_actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.report_column {
    border: 1px solid #F4F4F4;
    width: 40%;
    padding: 20px;
}